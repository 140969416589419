<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 1em;") CÓMO SE CALCULA. Paso 2
          p(style="margin-bottom: 1em;") #[strong Paso 2]-  Se calcula el precio medio (media aritmética, no media ponderada pues no conocemos el mix de ventas) de cada familia  (Precio Medio= Sumatorio todos los PVP / Numero de Referencias de venta), y lo multiplica por el consumo estándar de cada familia. El sumatorio de estas multiplicaciones nos da el IPS de la marca.
          div.mb-4
            card_3_-s-v-g_1
          p Por tanto viendo el cuadro, podemos ver que un cliente en caso de consumir lo mismo en cada marca tendría un gasto medio distinto.


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 1em;") HOW IT IS CALCULATED
          p(style="margin-bottom: 1em;") #[strong Step 2]-  We calculate the average arithmetic Price of each Family (Average Price = Sum of all the RRP / Number of sales references). Then we multiply that medium Price by the standard consumption of every Family. The sum of these multiplications gives us the IPS of the brand.
          div.mb-4
            card_3_-s-v-g_1_en
          p Therefore, looking at the table, we can see that a customer consuming the same in each brand would have a different average expense.
</template>

<script>
import Card_3_SVG_1 from "./assets/Card_3_SVG_1";
import Card_3_SVG_1_en from "@/components/guidedHelp/IPS/assets/Card_3_SVG_1_en";

export default {
  name: "Card_3",
  components: {Card_3_SVG_1_en, Card_3_SVG_1}
}
</script>

<style scoped>

</style>
